const menuDatas = [
  {
    name: 'Accueil',
    url: '/'
  },
  {
    name: 'Nos séjours',
    url: '/stays'
  },
  {
    name: 'Nos villes',
    url: '/cities'
  },
  {
    name: 'Contact',
    url: '/contacts'
  },
  {
    name: 'Blog',
    url: '/articles'
  },
  {
    name: 'Réservation',
    url: '/reservations',
    target: '_blank'
  }
]

export default menuDatas