import React, { useState } from "react"

import companyDatas from '../data/company'
import Menu from './menu'

const Header = ({search, pathname}) => {
  const [searchForm, setSearchForm] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleSearchForm = () => {setSearchForm(prevSearchForm => !prevSearchForm)}
  const toggleMenuOpen = () => {setMenuOpen(prevMenuOpen => !prevMenuOpen)}

  return (
    <>
      <div class="header">
        <div class="wrap">
          <div class="wrap_float">
            <div class="header__top">
              <div class="email">
                <span style={{color: '#fff'}}>{companyDatas.email}</span>
              </div>
              <div class="socials">
                <a href={companyDatas.instagram} target="_blank" rel="noreferrer" class="a instagram" />
                <a href={companyDatas.facebook} target="_blank" rel="noreferrer" class="a facebook" />
              </div>
            </div>
            <div class="header__bottom">
              <a href="/" class="logo" style={{display: 'flex', alignItems: 'center'}}>
                <img src={companyDatas.icon} alt="cotraveling" style={{height: 34, marginRight: 10}} />
                <span>{companyDatas.name}</span>
              </a>
              <div class="menu" id="js-menu" style={menuOpen ? {display: 'block'} : {}}>
                <div class="close" onClick={toggleMenuOpen} />
                <div class="scroll">
                  <div class="scroll_wrap">
                    <Menu pathname={pathname} /> 
                  </div>
                  <div class="bottom">
                    <div class="email">
                      <span style={{color: '#fff'}}>{companyDatas.email}</span>
                    </div>
                    <div class="socials">
                      <div class="links">
                        <a href={companyDatas.facebook} class="fb"></a>
                        <a href={companyDatas.instagram} class="instagram"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {search ? <div class="search_link" id="search_link" onClick={toggleSearchForm} /> : ''}
              <div class="mobile_btn" id="mobile_btn" onClick={toggleMenuOpen}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {searchForm ? <> <div class="search_form" id="search_form">
        <div class="wrap">
          <div class="wrap_float">
            <input type="text" class="input" placeholder="Search..." />
            <button type="submit" class="submit"></button>
            <div class="close" onClick={toggleSearchForm} />
          </div>
        </div>
      </div>
      <div class="overlay" id="overlay" /> </> : ''}
    </>
  )
}

export default Header
