import React from 'react'

import companyDatas from '../data/company'
import Menu from './menu'

const moreItems = [
  {
    name: 'Mentions Légales',
    url: '/privacy'
  }
]

const Footer = ({ pathname }) => {
  return (
    <div class="footer">
      <div class="footer_top">
        <div class="wrap">
          <div class="wrap_float">
            <div class="footer_head_mobile">
              <div class="logo">{companyDatas.name}</div>
              <div class="text">{companyDatas.description}</div>
            </div>
            <div class="footer_top_menu">
              <Menu pathname={pathname} />
            </div>
            <div class="socials">
              <a href={companyDatas.facebook} class="a facebook" />
              <a href={companyDatas.instagram} class="a instagram" />
            </div>
          </div>
        </div>
      </div>
      <div class="footer_center">
        <div class="wrap">
          <div class="wrap_float">
            <div class="footer_center_left">
              <a href="/" class="logo">{companyDatas.name}</a>
              <div class="text">{companyDatas.description}</div>
            </div>
            <div class="footer_center_menu">
              <Menu pathname={pathname} moreItems={moreItems} />
            </div>
            <div class="footer_center_right">
              <div class="_title">CONTACTS</div>
              <div class="text">
                <p>Adresse : <b>{companyDatas.address}</b> </p>
                <p>E-mail : <b>{companyDatas.email}</b> </p>
                <p><a href={companyDatas.linkedin} target="_blank" rel="noreferrer">LinkedIn</a> </p>
              </div>
            </div>
            <div class="mobile_socials">
              <a href={companyDatas.facebook} class="a facebook"></a>
              <a href={companyDatas.instagram} class="a instagram"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        Copyright {(new Date()).getFullYear()} <a href="#">{companyDatas.url}</a> | All Right Reserved
      </div>
    </div>
  )
}

export default Footer
