import React from "react"
import { Helmet } from 'react-helmet'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../assets/scss/styles.css"

import Header from '../components/header'
import Footer from '../components/footer'

const IndexPage = ({ location }) => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CoTraveling - Mentions Légales</title>
        <meta name="description" content="Des séjours entre indépendants pour travailler, se détendre et échanger. Dubaï, Phuket, Tokyo et plus encore de destinations pour vos séjours de CoTraveling." />
      </Helmet>
      <div class="container">
        <Header pathname={location.pathname} />
        <div class="page_content single-page about-page contacts-page" style={{backgroundColor: '#171717', paddingBottom: 0}}>
          <div class="content-head">
            <div class="wrap">
              <div class="wrap_float">
                <div class="main">
                  <div class="title">
                    Mentions légales et traitement des données personnelles
                  </div>
                  <div class="contacts-columns" style={{color: '#f2f2f2'}}>
                    <h2 class="privacy-title">La société</h2>
                    <p class="privacy-text">
                      Khufu<br/>
                      DSO-IFZA<br/>
                      Dubai Silicon Oasis<br/>
                      Dubai - UAE<br/>
                      License N°5214
                    </p>
                    <h2 class="privacy-title">L'hébergeur</h2>
                    <p class="privacy-text">
                      Netlify<br/>
                      2343 3rd St #296<br/>
                      San Francisco<br/>
                      CA 94107<br/>
                      États-Unis
                    </p>
                    <h2 class="privacy-title">Notre politique de confidentialité</h2>
                    <p class="privacy-text">
                      Les informations recueillies font l’objet d’un traitement informatique destiné à répondre à vos questions. Le destinataire des données est uniquement CoTraveling.<br/>
                      Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, ainsi qu’à la Réglementation Européenne pour la Protection des Données 2016/679 (General Data Protection Regulation) mise en applications le 25 mai 2018 vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez exercer grâce au Formulaire de Contact ou en vous adressant à CoTraveling : privacy@cotraveling.io<br/>
                      Vous pouvez également, vous opposer au traitement des données vous concernant.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer pathname={location.pathname} />
      </div>
    </main>
  )
}

export default IndexPage
