import React from "react"

import menuDatas from '../data/menu'

const Menu = ({ pathname, moreItems = [] }) => {
  return (
    <ul>
      {menuDatas.map(menu => (
        <li key={menu.name}><a href={menu.url} class={pathname === menu.url ? 'active' : ''} target={menu.target || ''}>{menu.name}</a></li>
      ))}
      {moreItems.map(menu => (
        <li key={menu.name}><a href={menu.url} class={pathname === menu.url ? 'active' : ''}>{menu.name}</a></li>
      ))}
    </ul>
  )
}

export default Menu